import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function SuccessPage () {
  return (
    <Layout>
      <SEO title='Thank you' />
      <div style={{ textAlign: 'center' }}>
        <h1>Thank you</h1>
        <div>
          Coach Miller or a member of his team will reach out within 1 business day to collect your
          information in order to personalize the program.
        </div>
      </div>
    </Layout>
  )
}
